require('bc-image-loader');

const $ = require('jquery');
global.jQuery = $;
const _ = require('underscore');
const Grapnel = require('grapnel');

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();

require('../../../../shared/lib/handlebarsHelpersTime')();
require('../../common/js/sorting');
const { videoFinisher } = require('../../common/js/videofinisher');
const { onPlayerSetup, performPlayerPlay } = require('../../common/js/perform');
const utils = require('../../common/js/utils');
const Cookies = require('js-cookie');

const breakpoints = {
  MOBILE: 1,
  DESKTOP: 800,
  DESKTOP_XL: 1500,
};

//---------------------------------------------------------------------------------
// MAIN
class Main {

  init () {
    this.initNoTouch();
  }

  initNoTouch () {
    // remove no-touch class if on a touch device
    if (typeof window.ontouchstart !== 'undefined') {
      $('body').removeClass('no-touch');
    }
  }

  getDimensions () {
    const windowWidth = window && window.innerWidth ? window.innerWidth : $('body').innerWidth();
    let breakpoint = breakpoints.MOBILE;

    if (windowWidth >= breakpoints.DESKTOP && windowWidth < breakpoints.DESKTOP_XL) {
      breakpoint = breakpoints.DESKTOP;
    } else if (windowWidth >= breakpoints.DESKTOP_XL) {
      breakpoint = breakpoints.DESKTOP_XL;
    }

    return {
      width: windowWidth,
      height: $(window).height(),
      breakpoint: breakpoint,
    };
  }
}

//---------------------------------------------------------------------------------
// NAV
class Nav {
  constructor () {
    this.$body = $('body');
    this.$header = $('#header');
    this.$my_content_inner = $('body #content .inner-content');
    this.$scroll_top = 0;
    this.scrollTopBreak = 65;
    this.$customHeader = $('.customHeaderContainer');
    this.isMobileNavControlOpen = false;
  }

  init () {
    this.bindEventHandlers();
  }

  setMobileNavControlOpen (isOpen) {
    this.isMobileNavControlOpen = isOpen;

    let adjustSidebarHeight = false;

    if (!this.isMobileNavControlOpen) {
      this.$body.removeClass('mobile_menu_open');
      this.$my_content_inner.css({
        top: 0,
      });
      $(window).scrollTop(this.$scroll_top);
    } else {
      this.$scroll_top = $(window).scrollTop();
      this.$body.addClass('mobile_menu_open');
      this.$my_content_inner.css({
        top: (this.$scroll_top * -1) + this.scrollTopBreak,
      });
      adjustSidebarHeight = true;
    }

    const dimensions = main.getDimensions();
    const customHeight = $('.customHeaderContainer').length > 0 ? $('.customHeaderContainer').height() : 0;
    const $sidebar = $('#sidebar');
    $sidebar.height('auto');
    if (adjustSidebarHeight && dimensions.breakpoint === breakpoints.MOBILE) {
      $sidebar.height('calc(100% - ' + customHeight + 'px)');
    }
  }

  onWindowResize () {
    this.onWindowScroll();
  }

  onWindowScroll () {
    if (this.$customHeader.length <= 0) {
      return;
    }

    const customHeaderHeight = this.$customHeader.height();
    const scrollTop = $(window).scrollTop();

    const $sidebar = $('#sidebar');
    let sidebarTop = customHeaderHeight - scrollTop;
    if (sidebarTop < 0) {
      sidebarTop = 0;
    }
    $sidebar.css('top', sidebarTop);

    const $header = $('#header');
    const dimensions = main.getDimensions();
    if (dimensions.breakpoint === breakpoints.MOBILE) {
      $header.css('top', sidebarTop);
    } else {
      $header.css('top', '0px');
    }
  }

  bindEventHandlers () {
    $('.mobile_menu .menu_open, .mobile_menu .menu_closed').on('click', e => {
      e.preventDefault();
      this.setMobileNavControlOpen(!this.isMobileNavControlOpen);
    });
  }
}

//---------------------------------------------------------------------------------
// VIDEO OVERLAY
class Overlay {
  constructor () {
    this.gridIsPresent = false;
    this.gridIsRelated = false;
    this.gridApiPage = 'index';
    this.isLocked = true;
    this.videoPlayer = null;
    this.nextPage = $('.show_more').data('next-page');
    this.videoCache = {};
    this.playingVideoId = null;
    this.playVideoWasCalled = false;
    this.templateCache = {};
    this.templateKeyedCache = {};
    this.socialApiData = null;
    this.ctaApiData = null;
    this.lazyLoadScrollOffset = .8;
    this.lazyLoadIsDone = false;
    this.router = null;
    this.autoPlayPage = 0;

    // currently preloaded items =
    // video player
    // social data
    // cta data
    // video grid item template
    // cta template
    this.preloadCount = 5;

    // if a video request comes in (e.g. page loaded with a Grapnel route);
    // we want remember the video to play after we're done preloading
    this.preloadHeldVideo = null;
  }

  /* --------------------------------------------------------------- */
  /* INITIALIZATIION */
  init () {
    const $videoGrid = $('.video_grid');
    this.gridIsPresent = $videoGrid.length > 0;
    this.gridIsRelated = $videoGrid.hasClass('related');
    this.gridApiPage = $videoGrid.data('api-page-request');

    const $searchResults = $('.search-results__wrapper');
    this.searchIsPresent = $searchResults.length > 0;

    if ((this.gridIsPresent && !this.gridIsRelated) || this.searchIsPresent) {
      this.initVideoPlayer();
    }

    this.initSocial();
    this.initCtas();
    this.initTemplates();
    this.initRouter();
    this.initInteractions();

    if ((!this.gridIsPresent && !this.searchIsPresent) || this.gridIsRelated) {
      this.setIsLazyLoadDone(true);
    }
  }

  initVideoPlayer () {
    // we don't want to reload on change because they play in overlays
    videoFinisher.onNextVideo(() => {
      this.onNextVideo();
    });

    onPlayerSetup(player => {
      this.videoPlayer = player;
      // Disable autoplay to prevent multilingual background audio
      player.autoplay(false);

      player.ready(() => {
        if (this.preloadCount > 0) {
          this.decrementPreloadAndCheckLock();
        }
      });
    });
  }

  initTemplates () {
    if (this.gridIsPresent) {
      this.loadTemplate(window.bcGallery.getTemplatePath('/sites/showcase/partials/video_grid_item.hbs'), template => {
        this.templateKeyedCache['videoGridItem'] = template;
        this.decrementPreloadAndCheckLock();
      });
    } else if (this.searchIsPresent) {
      this.loadTemplate(window.bcGallery.getTemplatePath('/sites/showcase/partials/search_results_list.hbs'), template => {
        this.templateKeyedCache['searchResultsList'] = template;
        this.decrementPreloadAndCheckLock();
      });
    }

    this.loadTemplate(window.bcGallery.getTemplatePath('/components/cta.hbs'), template => {
      this.templateKeyedCache['cta'] = template;
      this.decrementPreloadAndCheckLock();
    });
  }

  initSocial () {
    $.ajax({
      url: window.baseUrl + '/api/social',
      success: data => {
        if (data && data.result) {
          this.socialApiData = data.result;
        } else {
          console.warn('BCSHOWCASE - social API load failed');
          console.warn(data);
        }

        // always decrement preload to prevent waiting on errors
        this.decrementPreloadAndCheckLock();
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  initCtas () {
    let ctasApiPage = this.gridApiPage;
    if (this.searchIsPresent) {
      ctasApiPage = 'search';
    }

    $.ajax({
      url: window.baseUrl + '/api/ctas/' + ctasApiPage + '/ignoreMobileTemplate',
      success: data => {
        if (data && data.result) {
          this.ctaApiData = data.result;
        } else {
          this.ctaApiData = { };
        }

        this.decrementPreloadAndCheckLock();
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  initRouter () {
    this.router = new Grapnel();

    this.router.get('/overlay/:videoId', req => {
      if (req && req.params && req.params.videoId) {
        this.playVideo(req.params.videoId);
      }
    });
  }

  initInteractions () {
    // since we are using Grapnel, there is no corresponding click-to-open overlay interaction
    // these are handled by the physical hashes in the page
    $('.close_overlay').on('click', e => {
      e.preventDefault();
      this.pushGrapnelState(null);
      this.setVideoOverlayOpen(false);
    });
  }

  /* --------------------------------------------------------------- */
  /* INTERACTIONS */
  playVideo (videoId) {
    // do not proceed if we're not ready based on preload count
    if (this.isLocked) {
      this.preloadHeldVideo = videoId;
      return;
    }

    // remember playing video id for nextVideo event
    this.playingVideoId = videoId;

    // we don't want autoplay video players to play unless there was
    // a specific action taken by the user - if this function was called
    // it would have been because of a click or grapnel route
    this.playVideoWasCalled = true;

    // clear the held video b/c user created a new interaction
    // since we last "remembered" a video
    this.preloadHeldVideo = null;

    // obtain video information based off of the id provided
    const $play = $('.play[data-bc-video-id="' + videoId + '"]');

    // track for autoplay next
    this.autoPlayPage = $play.data('bc-video-page') ? parseInt($play.data('bc-video-page'), 10) : 0;

    // on less than mobile widths, skip overlay and just navigate
    const dimensions = main.getDimensions();
    if (dimensions.breakpoint === breakpoints.MOBILE) {
      this.loadVideo(videoId, (url) => {
        document.location.href = utils.urlWithDevice(url && url.canonicalUrl);
      });
      return;
    }

    // show curtain and hide cta while loading video
    this.setVideoCurtainVisible(true);
    this.updateOverlayCta(null);

    // load video before playing so we can trigger other updates
    // in addition to playing it
    this.loadVideo(videoId, video => {
      // play the selected video
      performPlayerPlay(this.videoPlayer, video, err => {
        if (err) {
          console.warn('BCSHOWCASE - error loading video');
          console.warn(err);
        } else {
          this.setVideoCurtainVisible(false);
          this.videoPlayer.play();
        }
      });

      // update the selected video's overlay social information
      this.loadTemplate(window.bcGallery.getTemplatePath('/sites/showcase/partials/share_buttons.hbs'), template => {
        $('#overlay-social-container').html(template({
          site: this.socialApiData,
          social: this.socialApiData.social,
          baseUrl: window.socialBaseUrl,
          socialBaseUrl: window.socialBaseUrl,
          category: window.category,
          video: video,
          url: video.canonicalUrl,
          translations: window.translations,
          subPath: window.subPath,
        }));
      });

      // update the selected video's overlay CTA
      this.updateOverlayCta(video);

      // track this as a page view
      window.BCLS.analyticsEngine.manualTrackPageView(window.location.pathname + window.location.hash);
    });

    // show overlay
    this.setVideoOverlayOpen(true);
  }

  onWindowScroll () {
    const lazyLoadScrollTarget = $(document).innerHeight() - $(window).height();
    if (lazyLoadScrollTarget > 0 && $(window).scrollTop() >= (lazyLoadScrollTarget * this.lazyLoadScrollOffset)) {
      if (this.gridIsPresent) {
        this.loadNextVideoGridPage($('.show_more').data('category-id'));
      } else if (this.searchIsPresent) {
        this.loadNextSearchPage($('.search-results__wrapper').data('search-query'));
      }
    }
  }

  onWindowResize () {
    this.adjustLastVideoWidths();
  }

  /* --------------------------------------------------------------- */
  /* EVENTS */
  onNextVideo () {
    // do nothing if we cannot determine the next video to play
    if (!window.category || !this.playingVideoId) {
      return;
    }

    // do nothing if site is NOT autoplay next
    if (window.site && !window.site.autoplayNext) {
      return;
    }

    // obtain the next video to load and play it
    const url = window.baseUrl + '/api/videos/' + window.category.slug + '/video/' + this.playingVideoId + '/next';
    const data = { page: this.autoPlayPage };

    $.ajax(url, {
      dataType: 'json',
      data: data,
      success: data => {
        if (data && data.result && data.result.id) {
          // update autoplay page
          this.autoPlayPage = data.result.page;

          // piggyback off of playVideo logic but prime the video cache here
          // since we will try to load the same thing later in playVideo
          this.videoCache[this.getVideoUrl(data.result.id)] = data.result;
          this.playVideo(data.result.id);
        } else {
          console.warn('BCSHOWCASE - could not find next video to play');
        }
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  /* --------------------------------------------------------------- */
  /* HELPERS */
  loadTemplate (url, callback) {
    if (this.templateCache[url]) {
      return callback(this.templateCache[url]);
    }

    $.ajax({
      url: url,
      success: data => {
        this.templateCache[url] = window.bcGallery.Handlebars.compile(data);
        callback(this.templateCache[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  getVideoUrl (videoId) {
    let url = window.baseUrl + '/api';
    if (window.category.slug) {
      url += '/videos/' + window.category.slug;
    }
    return url + '/video/' + videoId;
  }

  loadVideo (videoId, callback) {
    const url = this.getVideoUrl(videoId);

    if (this.videoCache[url]) {
      return callback(this.videoCache[url]);
    }

    $.ajax({
      url: url,
      success: data => {
        if (!data || !data.result) {
          console.warn('BCSHOWCASE - video not loaded for [' + videoId + ']');
          return callback(false);
        }
        this.videoCache[url] = data.result;
        callback(this.videoCache[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  loadNextVideoGridPage (categoryId) {
    if (this.isLocked) {
      setTimeout(() => this.onWindowScroll(), 100);
      return;
    }
    if (this.lazyLoadIsDone) {
      return;
    } else if (!this.nextPage) {
      // end lazy load if we've reached the end
      return this.setIsLazyLoadDone(true);
    }

    this.setIsLocked(true);

    this.loadVideosForCategory(categoryId, this.nextPage, videos => {
      if (!videos) {
        return;
      }

      // update query for use with video grid items for autoplay next
      const query = JSON.parse(JSON.stringify(window.query));
      query.page = this.nextPage;

      // update next page after the query
      this.nextPage = videos.nextPage;

      // we subtract 1 since this template treats the first video as the "hero" video
      let videoIndex = videos.start - 1;
      _.each(videos.items, function (video) {
        video.videoIndex = videoIndex;
        videoIndex++;
      });

      // assumption here is that we've preloaded the templates needed
      // for the loops below - we do this to avoid closure issues with
      // indicies within a loop + loadTemplate callbacks
      const $videoGrid = $('.video_grid');
      const videoGridTemplate = this.templateKeyedCache['videoGridItem'];
      const ctaTemplate = this.templateKeyedCache['cta'];
      const ctaBaseId = window.isMobile ? 'video-feed-mobile' : 'video-feed';

      // append video grid items for each
      _.each(videos.items, (video) => {
        $videoGrid.append(videoGridTemplate({
          video: video,
          videoIndex: video.videoIndex,
          ctas: this.ctaApiData,
          baseUrl: window.baseUrl,
          category: window.category,
          imageTranscoder: window.BCLS.imageTranscoder,
          query: query,
          ignoreCustomDimensions: true,
        }));

        // check if we also require a repeat CTA
        if (this.ctaApiData[ctaBaseId + '_' + video.videoIndex]) {
          const html = ctaTemplate({
            id: ctaBaseId,
            forIndex: video.videoIndex,
            ctas: this.ctaApiData,
            ignoreCustomDimensions: true,
          });
          $videoGrid.append('<div class="video with-ad">' + html + '</video>');
        }
      });

      // re-enable play on newly appended grid items
      this.setIsLocked(false);

      // adjust grid widths
      this.adjustLastVideoWidths();
      window.bcGallery.imageLoader.loadImages();
    });
  }

  loadNextSearchPage (query) {
    if (this.isLocked) {
      setTimeout(() => this.onWindowScroll(), 100);
      return;
    }
    if (this.lazyLoadIsDone) {
      return;
    } else if (!this.nextPage) {
      // end lazy load if we've reached the end
      return this.setIsLazyLoadDone(true);
    }
    this.setIsLocked(true);

    this.loadVideosForSearch(query, this.nextPage, videos => {
      if (!videos) {
        return;
      }
      this.nextPage = videos.nextPage;

      // assumption here is that we've preloaded the templates needed
      // for the loops below - we do this to avoid closure issues with
      // indicies within a loop + loadTemplate callbacks
      const $searchResults = $('.search-results__wrapper');
      const searchResultsListTemplate = this.templateKeyedCache['searchResultsList'];

      // append new search results
      const html = searchResultsListTemplate({
        site: window.site,
        imageTranscoder: window.BCLS.imageTranscoder,
        baseUrl: window.baseUrl,
        videos: videos,
      });

      $searchResults.append(html);
      window.bcGallery.imageLoader.loadImages();

      // re-enable play on newly appended search
      this.setIsLocked(false);
    });
  }

  loadVideosForCategory (categoryId, page, callback) {
    $.ajax(window.baseUrl + '/api/videos/' + categoryId + '?page=' + page, {
      dataType: 'json',
      success: function (data) {
        if (!data || !data.result) {
          console.warn('BCSHOWCASE - videos not loaded for category [' + categoryId + '][' + page + ']');
          return callback(false);
        }
        callback(data.result);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  loadVideosForSearch (query, page, callback) {
    $.ajax(window.baseUrl + '/api/videos?q=' + encodeURIComponent(query) + '&page=' + page, {
      dataType: 'json',
      success: function (data) {
        if (!data || !data.result) {
          console.warn('BCSHOWCASE - search failed for [' + query + '][' + page + ']');
          return callback(false);
        }
        callback(data.result);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  decrementPreloadAndCheckLock () {
    this.preloadCount--;
    if (this.preloadCount < 0) {
      this.preloadCount = 0;
    }

    this.setIsLocked(this.preloadCount > 0);
    this.checkPreloadHeldVideo();
  }

  setIsLocked (isLocked) {
    this.isLocked = isLocked;

    const $play = $('.play');
    if (this.isLocked) {
      $play.addClass('disabled');
    } else {
      $play.removeClass('disabled');
    }
  }

  setVideoCurtainVisible (isVisible) {
    const $videoCurtain = $('.overlay .video-curtain');

    if (isVisible) {
      $videoCurtain.stop().show();
    } else {
      $videoCurtain.stop().fadeOut();
    }
  }

  setVideoOverlayOpen (isOpen) {
    const $overlay = $('.overlay');

    if (isOpen) {
      $overlay.addClass('active');
    } else {
      $overlay.removeClass('active');
      this.videoPlayer.pause(true);// works for either player
    }
  }

  setIsLazyLoadDone (isDone) {
    this.lazyLoadIsDone = isDone;
    if (this.lazyLoadIsDone) {
      $('.show_more').remove();
      $('.search-results__loading').remove();
    }
  }

  updateOverlayCta (video) {
    const $overlay = $('#overlay');
    const $overlayCta = $('#overlay-cta-container');

    $overlay.removeClass('with-cta');
    $overlayCta.html('');

    if (!video) {
      return;
    }

    const ctasCopy = this.copyCtasWithCfDependentValues(video);
    if (!ctasCopy || !ctasCopy['overlay'] || !ctasCopy['overlay'].isComplete) {
      return;
    }

    const ctaTemplate = this.templateKeyedCache['cta'];
    $overlay.addClass('with-cta');
    $overlayCta.html(ctaTemplate({
      id: 'overlay',
      ctas: ctasCopy,
    }));
  }

  copyCtasWithCfDependentValues (video) {
    const ctasCopy = JSON.parse(JSON.stringify(this.ctaApiData));

    // mimic the behavior of the cta post assembler
    const videoCustomFields = video.customFields ? video.customFields : { };
    _.each(ctasCopy, function (cta, ctaId) {
      if (cta.isComplete && !_.isEmpty(cta.cfDependencies)) {
        _.each(cta.cfDependencies, function (cfDependency) {
          if (cfDependency.cfValue === videoCustomFields[cfDependency.cfField]) {
            ctasCopy[ctaId].cfDependentValue = cfDependency.cfHtml;
          }
        });
        delete ctasCopy[ctaId].cfDependencies;
        if (!ctasCopy[ctaId].value && !ctasCopy[ctaId].cfDependentValue) {
          delete ctasCopy[ctaId];
        }
      }
    });

    return ctasCopy;
  }

  checkPreloadHeldVideo () {
    if (!this.isLocked && this.preloadHeldVideo) {
      this.playVideo(this.preloadHeldVideo);
    }
  }

  adjustLastVideoWidths () {
    if (!this.setIsLazyLoadDone) {
      return;
    }

    // reset previously added styles
    const $grid = $('.video_grid');
    $grid.find('.video').removeAttr('style');

    // find last video if it exists
    const $gridLastVideo = $grid.find('.video:last-child');
    if ($gridLastVideo.length <= 0) {
      return;
    }

    // find number of items in same row to calculate border widths
    // which are excluded from position() calls below - we start at
    // 1 to count one's self since borders are to the right
    let previousCount = 1;
    let $nextLast = $gridLastVideo.prev();
    let nextLastRowComplete = false;
    while (!nextLastRowComplete && $nextLast.offset() && $nextLast.offset().left >= $grid.offset().left) {
      previousCount++;

      // we don't check beyond the first in the last "row"
      // which logically is the 0th position
      if ($nextLast.position().left === 0) {
        nextLastRowComplete = true;
      }

      $nextLast = $nextLast.prev();
    }

    // expand last video to fill remaining grid width
    // perform 2px pixel adjustment for each element in the row
    let gridLastVideoWidthCalculated = $grid.width() - $gridLastVideo.position().left;
    gridLastVideoWidthCalculated = gridLastVideoWidthCalculated - (previousCount * 2);
    $gridLastVideo.width(gridLastVideoWidthCalculated);
  }

  pushGrapnelState (state) {
    window.location.hash = state ? state : '';
  }
}

//---------------------------------------------------------------------------------
// DETAIL
class Detail {
  constructor () {
    this.$detailVideo = null;
    this.ASPECT_RATIO = 16 / 9;
  }

  init () {
    this.$detailVideo = $('#detail_page .video');
  }

  onWindowResize () {
    if (this.$detailVideo.length <= 0) {
      return;
    }

    // will be overriden by CSS !important at wider than mobile widths
    this.$detailVideo.height(this.$detailVideo.width() * (1 / this.ASPECT_RATIO));
  }
}

//---------------------------------------------------------------------------------
// SEARCH
class Search {
  init () {
    this.initInteractions();
  }

  initInteractions () {
    $('.search-input__button').on('click', e => {
      e.preventDefault();
      this.submitSearch($(e.currentTarget).closest('.search-input')
        .find('.search-input__input')
        .val());
    });

    $('.search-input__input').on('keyup', e => {
      if (e.keyCode === 13) {// enter
        if (e.originalEvent.isComposing) { // don't execute search if keyboard is composing input
          return;
        }
        this.submitSearch($(e.currentTarget).val());
      }
    });
  }

  submitSearch (query) {
    if (query && query.length > 0) {
      // update a cookie so that we can show a "back to search results"
      // as a result of processing in "initCookies"
      Cookies.set('BCSHOWCASE_SEARCH_Q', query);

      document.location.href = this.createSearchUrl(query);
    }
  }

  createSearchUrl (query) {
    return window.baseUrl + '/search?q=' + encodeURIComponent(query);
  }
}

const main = new Main();
const nav = new Nav();
const overlay = new Overlay();
const detail = new Detail();
const search = new Search();

function onWindowResize () {
  nav.onWindowResize();
  overlay.onWindowResize();
  detail.onWindowResize();
}

function onWindowScroll () {
  overlay.onWindowScroll();
  nav.onWindowScroll();
}

$(document).ready(function () {
  window.bcGallery.imageLoader.loadImages();

  main.init();
  nav.init();
  overlay.init();
  detail.init();
  search.init();

  onWindowResize();
  onWindowScroll();
});

$(window).scroll(onWindowScroll);

$(window).resize(onWindowResize);
